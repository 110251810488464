/* reset css */
html.hidden {
  overflow: hidden;
}
body.hidden {
  height: auto;
  overflow: hidden;
}
a:focus {
  outline: none;
}
a:active {
  color: #111;
}
a:active,
a:focus,
a:hover {
  text-decoration: none;
}
a:focus,
a:hover {
  color: inherit;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: none;
}

body,
html {
  min-height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  letter-spacing: normal;
  font-weight: normal;
}

fieldset,
img,
abbr,
acronym {
  border: 0 none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border: 0 none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

img,
video {
  width: 100%;
  border: 0;
}

audio,
canvas,
video {
  display: inline-block;
  display: inline;
  zoom: 1;
}

img {
  display: block;
  width: 100%;
  image-rendering: -moz-auto;
  /*firefox*/
  image-rendering: -o-auto;
  /*opera*/
  image-rendering: -webkit-optimize-contrast;
  /*webkit*/
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  /* -webkit-perspective: 1; */
}

ul,
ol,
dl,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

i {
  font-style: normal;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select,
label {
  vertical-align: middle;
}

button {
  cursor: pointer;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  vertical-align: middle;
  font-weight: normal;
  letter-spacing: normal;
  background: none;
}

button,
input {
  line-height: normal;
  box-shadow: none;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: none;
}

address {
  font-style: normal;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
}

input::-webkit-date-and-time-value {
  margin-top: 0px;
  text-align: left;
  width: 100%;
}

/* common style */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-gov.min.css");
@font-face {
  font-family: 'KimjungchulScript-Bold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/KimjungchulScript-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
body {
  min-height: 100vh;
  font-family: "Pretendard GOV", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #111;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --purple: #8c55e9;
  --blue: #4975e6;
  --gradient: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);
  --shadow: 0px 0px 20px #7262e8;


  --footer-z-index: 10;
  overflow-x: hidden;
}

.mbBr {
  display: none;
}

::-webkit-scrollbar { width: 6px; height: 6px; } 
::-webkit-scrollbar-track { background: transparent; } 
::-webkit-scrollbar-thumb { background: #888; border-radius: 999px; background-clip: padding-box; border-top: 7px solid transparent; border-bottom: 10px solid transparent; border-right: 2px solid transparent; border-left: 2px solid transparent; }

input,
select,
button,
textarea {
  font-family: "Pretendard GOV", sans-serif;
  font-size: 16px;
  color: #111;
}

.pcBr {
  display: block;
}

.mbBr {
  display: none;
}

.pcImg {
  display: block;
}

.mbImg {
  display: none;
}
p + p {
  margin: 0;
}

@media (max-width: 1520px) {
  body {
    font-size: calc(100vw * (16 / 1520));
  }

  input,
  select,
  button,
  textarea {
    font-size: calc(100vw * (16 / 1520));
  }
}

@media (max-width: 1024px) {
  ::-webkit-scrollbar { display: none; } 

  body {
    font-size: calc(100vw * (16 / 375));
  }

  input,
  select,
  button,
  textarea {
    font-size: calc(100vw * (16 / 375));
  }

  .pcBr {
    display: none;
  }

  .mbBr {
    display: block;
  }

  .pcImg {
    display: none;
  }

  .mbImg {
    display: block;
  }
}  