/* COMMON */
.page {
  width: 100%;
}
.page .section {
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}
.page .section.full {
  max-width: initial;
  padding: 0;
}

/* INTRO PAGE */
/* S:: 2-1 온강 소개 */
.intro .banner-main {
  width: 100%;
  background: url("/src/resources/image/intro/intro-banner.png") no-repeat
    center / 1920px 660px #000;
  height: 440px;
}
.intro .banner-main__inner {
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
  position: relative;
}
.intro .banner-main .text-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.intro .banner-main .text-wrap .tit {
  color: #fff;
  font-size: 62px;
  line-height: 44px;
  font-weight: 700;
  display: flex;
}
.intro .banner-main .text-wrap .tit .line-img {
  max-width: 58px;
  height: 3px;
  margin-right: 20px;
}
.intro .banner-main .text-wrap .heading-text {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.intro .banner-main .text-wrap .heading-text .sub-title {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  white-space: pre;
  font-weight: 500;
}

.intro .intro-wrap .intro-top {
  width: 100%;
  height: 651px;
  background: url("/src/resources/image/intro/intro-bg.png") no-repeat top /
    cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 164px;
  margin-bottom: 200px;
}
.intro .intro-wrap .member-img {
  max-width: 1260px;
}

.intro .intro-wrap .intro-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}
.intro .intro-wrap .intro-bottom .brand-logo {
  max-width: 292px;
}
.intro .intro-wrap .intro-bottom .summary {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.intro .intro-wrap .intro-bottom .summary .typo-img {
  max-width: 646px;
}
.intro .intro-wrap .intro-bottom .summary .text-wrap {
  text-align: center;
}
.intro .intro-wrap .intro-bottom .summary .text-wrap h3 {
  font-size: 36px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 30px;
}
.intro .intro-wrap .intro-bottom .summary .text-wrap h3 strong {
  color: #da250b;
  font-weight: inherit;
}
.intro .intro-wrap .intro-bottom .summary .text-wrap h4 {
  font-size: 18px;
  line-height: 160%;
  font-weight: 500;
}
/* E:: 2-1 온강 소개 */

/* S:: 2-2 온강의 특별함 */
.special-banner {
  height: 850px;
  background: url("/src/resources/image/intro/intro-special-banner.png")
    no-repeat center / 1920px 850px #000;
}
.special-banner .text-wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  flex-direction: column;
}
.special-banner .text-wrap h3 {
  font-size: 66px;
  line-height: 140%;
  font-weight: 800;
  margin: 30px 0 40px 0;
}
.special-banner .text-wrap h3 strong {
  color: #da250b;
  font-weight: inherit;
}
.special-banner .text-wrap h4 {
  font-size: 26px;
  line-height: 31px;
  font-weight: 300;
}
.special-banner .text-wrap h4.bottom {
  line-height: 36px;
}
.special-banner .text-wrap h4 .high-light {
  font-weight: 800;
  display: inline-block;
  border-bottom: 3px solid #fff;
  margin: 0 10px;
  line-height: 31px;
}

.reason-item {
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}
.reason-item.full {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.reason-item .item-wrap {
  display: flex;
  gap: 30px;
  padding-top: 100px;
}
.reason-item .count-num {
  width: 60px;
  height: 60px;
  background: #da250b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: 800;
  color: #fff;
}
.reason-item .heading {
  font-size: 36px;
  line-height: 140%;
  font-weight: 800;
  margin-top: -10px;
  margin-bottom: 40px;
}
.reason-item .heading strong {
  color: #da250b;
}
.reason-item .sub-text {
  font-size: 18px;
  line-height: 160%;
  letter-spacing: -0.2px;
  font-weight: 500;
}
.reason-item .sub-text b {
  font-weight: 800;
}
.reason-item .img-list {
  width: 1009px;
  margin: 50px auto 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px 0;
}
.reason-item .img-item {
  margin-left: -40px;
}

.imgWrap {
  width: 1009px;
  margin: 50px auto 0;
}

.lawyerImgList {
  width: 252px;
  height: 375px;
  margin-left: -1px;
  box-shadow: -20px 0 30px 0 rgba(0, 0, 0, 0.25);
}
.lawyerImgList img {
  height: 100%;
}
.lawyerImgList:nth-child(3) {
  margin-right: 30px;
}
.lawyerImgList.total {
  display: none;
}

.reason-item .lecture-box {
  width: 100%;
  max-width: 1920px;
  margin: 40px auto 0 auto;
}

.reason-item.bg {
  background: url("/src/resources/image/intro/special-bg.png") no-repeat center /
    1920px 865px;
  margin-top: -30px;
}
.reason-item .inner-flex {
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}
.reason-item .inner-flex .item-wrap {
  flex-direction: column;
}
.reason-item .counselor-img {
  max-width: 1280px;
  padding: 0 10px;
  margin: 50px auto 0 auto;
}
.reason-item .hair-img {
  max-width: 1280px;
  margin: 50px auto 0 auto;
}
/* E:: 2-2 온강의 특별함 */

/* S:: 2-3 전문가 소개 */
.expert-search {
  padding-top: 80px;
  margin-bottom: 40px;
}
.expert-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  row-gap: 50px;
  padding-bottom: 70px;
}
.expert-item {
  position: relative;
  cursor: pointer;
  background: linear-gradient(180deg, #3f454d 54%, #000 142.93%);
  height: 448px;
}
.expert-item::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 5px solid transparent;
  box-sizing: border-box;
  transition: 0.2s;
  pointer-events: none;
}
.expert-item:hover::after {
  border-color: #da250b;
}
.expert-item .text-wrap {
  position: absolute;
  left: 30px;
  bottom: 40px;
  color: #fff;
  z-index: 1;
}
.expert-item .text-wrap::before {
  position: absolute;
  top: -9px;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #da250b;
  content: "";
}
.expert-item .logo-box-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
}
.expert-item .title {
  width: max-content;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.expert-item .sub-title {
  width: max-content;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}

.expert-item:hover .sub-title {
  color: #da250b;
  transition: 0.2s;
}
.expert-item .logo-box {
  width: 100%;
  max-width: 28px;
  height: 28px;
}
.expert-item .logo-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.expert-item .link {
  width: 100%;
  height: 100%;
  display: block;
}
.expert-item .link > img {
  height: 100%;
  object-fit: contain;
}
.expert-item .link::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: red;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 41.72%, #000 109.93%);
}

/* empty list */
.empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 213px 0;
  color: #c4c4c4;
  font-size: 18px;
  line-height: 21px;
}

.expert-detail:not(.example) {
  width: 100%;
  height: 1004px;
  margin: 0 auto;
  background: url("/src/resources/image/intro/expert-bg.jpg") no-repeat center /
    1920px 1004px;
  position: relative;
}
.expert-detail:not(.example)::after {
  content: "";
  width: 1920px;
  height: 1004px;
  background: url("/src/resources/image/intro/expert-line-bg.png") no-repeat
    right / 1036px 1004px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.expert-detail__inner {
  position: relative;
  z-index: 1;
  height: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.expert-detail__inner .text-wrap {
  width: 520px;
  height: 100%;
  padding-top: 120px;
  color: #fff;
}
.expert-detail__inner .text-wrap .logo-img {
  width: 53px;
  margin-right: 5px;
}

.expert-detail .img-box img {
  max-width: 706px;
}

.expert-detail .text-wrap {
  display: flex;
  flex-direction: column;
}
.expert-detail .text-wrap .bottom {
  flex: 1;
  overflow-y: scroll;
  margin-bottom: 50px;
}
.expert-detail .text-wrap .badge {
  width: auto;
  height: auto;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  padding: 6px 30px;
  background: #da250b;
  display: inline-block;
  margin-bottom: 20px;
}
.expert-detail .name-wrap {
  display: flex;
  align-items: baseline;
  margin-bottom: 40px;
}
.expert-detail .name-wrap .title {
  font-size: 62px;
  font-weight: 800;
  letter-spacing: 20px;
}
.expert-detail .name-wrap .sub-title {
  font-size: 36px;
  color: #da250b;
}

.expert-detail .scroll-item {
  display: flex;
  gap: 30px;
}
.scroll-item + .scroll-item {
  margin-top: 50px;
}
.expert-detail .scroll-item .category {
  font-size: 36px;
  color: #888;
}
.expert-detail .resume-list {
  flex: 1;
}
.expert-detail .resume-list li {
  word-wrap: break-word;
  word-break: break-all;
  padding-left: 11px;
  position: relative;
}
.expert-detail .resume-list li::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.resume-list li {
  margin-top: 12px;
}

.expert-detail .resume-item {
  word-wrap: break-word;
  word-break: break-all;
  padding-left: 11px;
  position: relative;
}
.expert-detail .resume-item::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.resume-item + .resume-item {
  margin-top: 12px;
}

.expert-detail .text-wrap .bottom::-webkit-scrollbar {
  width: 5px;
}
.expert-detail .text-wrap .bottom::-webkit-scrollbar-track {
  background: #080808;
}
.expert-detail .text-wrap .bottom::-webkit-scrollbar-thumb {
  -webkit-border-raidus: 8px;
  -mox-border-radius: 8px;
  border-radius: 8px;
  background: #da250b;
}

.expert-detail.example {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.expert-detail.example .example-inner {
  max-width: 1500px;
  position: relative;
  margin-top: 80px;
}
.expert-detail.example .example-inner .head .title {
  font-size: 36px;
  font-weight: 800;
  line-height: 43px;
}
.expert-detail.example .example-inner .btn-more {
  border: 1px solid #000;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 30px;
  gap: 30px;
  white-space: nowrap;
  color: #111;
  font-weight: 600;
}
.expert-detail.example .example-inner .swiper_wrap .swiper {
  padding: 30px 120px 70px 120px;
}
.expert-detail.example .example-inner .slide_btn {
  bottom: initial;
  top: 50%;
  transform: translate(0, -50%);
}
.expert-detail.example .btn-box {
  width: 100%;
  border-top: 1px solid #e4e4e4;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expert-detail.example .btn-box .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 50px;
  font-weight: 600;
  padding: 17px 0;
  max-width: 560px;
  background: #da250b;
  color: #fff;
}

/* E:: 2-3 전문가 소개 */

/* S:: 2-4 언론보도 */
.expert-column-wrap .column-inner {
  padding-top: 120px;
}
.expert-column-wrap .column-inner .top {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 30px;
}
.expert-column-wrap .column-inner .bottom {
  padding-top: 80px;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}
/* E:: 2-4 언론보도 */

/* S:: 2-4 언론보도 - 에디트 */
.section.column .expert-editor-wrap {
  border: none;
  padding-bottom: 0;
}
.expert-editor-wrap {
  padding-bottom: 70px;
  margin-bottom: 70px;
  border-bottom: 1px solid #e4e4e4;
}
.expert-editor-wrap .editor-head {
  border-bottom: 1px solid #e4e4e4;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.expert-editor-wrap .editor-head .head-inner {
  border-top: 3px solid #da250b;
  max-width: 1260px;
  margin: 0 10px;
  width: 100%;
}
.expert-editor-wrap .editor-head .float-type {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding: 6px 30px;
  background: #da250b;
  display: inline-block;
  margin-bottom: 30px;
}
.expert-editor-wrap .editor-head .head-box {
  display: flex;
  gap: 20px;
}
.expert-editor-wrap .editor-head .text-wrap {
  flex: 1;
}
.expert-editor-wrap .editor-head .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 24px;
}
.expert-editor-wrap .editor-head .sub-title {
  font-size: 14px;
  color: #888;
}
.expert-editor-wrap .editor-head .img-box {
  flex: 1;
  max-width: 246px;
  margin-bottom: 0;
}
.expert-editor-wrap .editor-box {
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
  min-height: 400px;
}
.other-list .btn-box {
  width: 100%;
  border-top: 1px solid #e4e4e4;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.other-list .btn-box .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 50px;
  font-weight: 600;
  padding: 17px 0;
  max-width: 560px;
  background: #da250b;
  color: #fff;
}

/* E:: 2-4 언론보도 - 에디트 */

/* S:: 2-5 오시는 길 */
.expert-map {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  padding-top: 140px;
  overflow: hidden;
  min-height: 1000px;
}
.expert-map::before {
  content: "";
  display: block;
  width: 470px;
  height: 570px;
  position: absolute;
  left: 0;
  top: 0;
  background: url("/src/resources/image/intro/map_bg.jpg") no-repeat center /
    470px 570px;
  transform: translateY(-160px);
}
.expert-map .map-inner {
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.expert-map .map-contents {
  width: 100%;
  min-height: 650px;
  position: relative;
  margin-bottom: 20px;
}
.expert-map .map-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}
.expert-map .map-contents .map {
  width: 100%;
  height: 550px;
}
.expert-map .map-item {
  flex: 1;
}
.expert-map .map-item .map-item-inner {
  cursor: pointer;
  font-size: 20px;
  color: #c4c4c4;
  line-height: 24px;
  text-align: center;
  padding: 20px 0;
  background: #111;
}

.expert-map .map-item .map-item-inner.active {
  background: #da250b;
  color: #fff;
  font-weight: 700;
}
.expert-map .map-info-item {
  width: 100%;
  display: flex;
  padding: 40px 0;
  border-bottom: 1px solid #e4e4e4;
}
.expert-map .map-info-item .map-text {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.expert-map .map-info-item .map-text .title {
  color: #da250b;
  min-width: 70px;
  font-weight: 700;
}
.expert-map .map-info-item .map-text .sub-title {
  font-weight: 500;
}
.expert-map .map-info-item .map-text:last-of-type {
  max-width: 520px;
}
/* E:: 2-5 오시는 길 */

/* S:: 6-1 의뢰인 후기 */
.review-wrap {
  padding-top: 120px;
}
.review-wrap .review-top {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 30px;
}
.review-wrap .review-bottom {
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}

/* 상세 */
.edit.story .head-box {
  padding-top: 30px;
}
.edit.story .other-list .btn-box {
  border: none;
  padding: 0;
}
/* E:: 6-1 의뢰인 후기 */

/* S:: 6-2 유튜브 */
.youtube-wrap {
  padding-top: 120px;
}
.youtube-wrap .youtube-top {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 80px;
}
.youtube-wrap .best-wrap {
  display: flex;
  gap: 50px;
  align-items: flex-start;
  padding-bottom: 80px;
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
  padding-top: 30px;
}
.youtube-wrap .best-wrap .img-box-container {
  flex: 1;
  max-width: 640px;
  width: 100%;
}
.youtube-wrap .best-wrap .img-box {
  margin: 0;
  width: 640px;
  height: 405px;
}
.youtube-wrap .best-wrap .img-box img {
  object-fit: cover;
}
.youtube-wrap .best-wrap .text-wrap {
  flex: 1;
}
.youtube-wrap .text-wrap .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.youtube-wrap .text-wrap .sub-title {
  font-size: 16px;
  line-height: 140%;
  color: #888;
  margin-bottom: 50px;
}
.youtube-wrap .youtube-bottom {
  width: 100%;
  max-width: 1500px;
  padding: 80px 10px 0;
  margin: 0 auto;
}
.category-item + .category-item {
  margin-top: 80px;
}
/* E:: 6-2 유튜브 */

/* S:: 6-3 컬럼 */
.story-column-wrap .column-inner {
  padding-top: 120px;
}
.story-column-wrap .column-inner .top {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 30px;
  margin-bottom: 40px;
}
.story-column-wrap .column-inner .bottom {
  padding-top: 80px;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}
.story-column-wrap .column-inner .bottom .column-list-container {
  padding-top: 40px;
}
/* E:: 6-3 컬럼 */

/* S:: 4 상활별 대응 전략 */
.page .situation .text-wrap .heading-text {
  line-height: 140%;
  margin-top: -20px;
  white-space: pre;
}
.page .situation .text-wrap .heading-text .sub-title {
  display: none;
}
.page .banner-main.medication {
  background: url("/src/resources/image/situation/situation-medication-banner.jpg")
    no-repeat center / 1920px 660px #000;
}
.page .banner-main.sales {
  background: url("/src/resources/image/situation/situation-sales-banner.jpg")
    no-repeat center / 1920px 660px #000;
}
.page .banner-main.smuggling {
  background: url("/src/resources/image/situation/situation-smuggling-banner.jpg")
    no-repeat center / 1920px 660px #000;
}
.page .banner-main.arrest {
  background: url("/src/resources/image/situation/situation-arrest-banner.jpg")
    no-repeat center / 1920px 660px #000;
}

strong {
  font-weight: inherit;
}
/* 1번째 직통 상담 섹션 */
.situation .apply-inner {
  width: 100%;
  height: 944px;
  background: url("/src/resources/image/situation/situation-bg.jpg") no-repeat
    center -1px / 1920px 944px #000;
  margin-bottom: 20px;
}
.situation .contents-wrap {
  width: 100%;
  max-width: 1280px;
  padding: 120px 10px 0;
  margin: 0 auto;
}
.situation .info-box {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.situation .info-box .text-wrap {
  width: 50%;
  height: 100%;
  flex: 1 1 auto;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px 0 0 5px;
}
.situation .info-box strong {
  color: #da250b;
  font-weight: inherit;
}
.situation .info-box .title {
  font-size: 36px;
  font-weight: 800;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e4e4e4;
}
.situation .info-box .sub-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.situation .info-box .sub-title:last-of-type {
  margin-bottom: 30px;
}
.situation .step-box {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.situation .step-box .step {
  display: flex;
  align-items: center;
  gap: 10px;
}
.situation .step-box .step-count {
  color: #888;
}
.situation .step-box .text {
  font-weight: 500;
  letter-spacing: -0.4px;
}
.situation .info-box .img-wrap {
  width: 100%;
  height: 100%;
}
.situation .info-box .img-wrap img {
  object-fit: cover;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

.situation .bottom-info {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.situation .bottom-info p {
  color: #fff;
}
.situation .bottom-info .title {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 15px;
}
.situation .bottom-info .title .light {
  font-weight: normal;
}
.situation .bottom-info .sub-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}
.situation .bottom-info .btn {
  width: 100%;
  max-width: 560px;
  background: #da250b;
  padding: 17px 0;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
}

.situation .focus-inner .contents-wrap {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
}
/* S:: situation common */
.situation .head-title {
  font-size: 36px;
  font-weight: 800;
  text-align: center;
  line-height: 54px;
  margin-bottom: 50px;
}
.situation .head-title strong {
  color: #da250b;
  font-weight: inherit;
}
.situation .head-title .light {
  font-size: inherit;
  font-weight: normal;
}
.situation .head-title .sub-title {
  font-weight: normal;
  font-size: 26px;
}
/* E:: situation common */

.situation .img-step-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
.situation .img-step-item {
  max-width: 620px;
  border: 1px solid #c4c4c4;
  padding: 18px;
  background: #fff;
}
.situation .img-step-item .top {
  position: relative;
  margin-bottom: 58px;
}
.situation .img-step-item .badge {
  font-size: 26px;
  padding: 7px 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  background: #da250b;
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}
.situation .focus-inner .bottom {
  text-align: center;
}
.situation .focus-inner .bottom .text {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.situation .focus-inner .way-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.situation .focus-inner .way-text .title {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}
.situation .focus-inner .gradient-line {
  display: inline-block;
  width: 3px;
  height: 70px;
  background: linear-gradient(to top, #da250b 31%, rgba(255, 255, 255, 0) 100%);
  margin-bottom: 10px;
}
.situation .focus-inner .way-text .sub-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  margin-bottom: 20px;
}
.situation .focus-inner .way-text .sub-title strong {
  font-weight: 800;
}
.situation .focus-inner .text-banner {
  display: inline-block;
  padding: 13px 40px;
  border: 1px solid #da250b;
  font-size: 36px;
  font-weight: 500;
  line-height: 140%;
  background: rgba(218, 37, 11, 0.1);
}
.situation .focus-inner .text-banner strong {
  font-weight: 800;
  color: #da250b;
}

.situation .process-inner {
  padding-top: 80px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.situation .process-list {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.situation .process-item {
  position: relative;
  flex: 1;
  height: 850px;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: 0.5s;
}
.situation .process-item .float-img-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: brightness(0.5) grayscale(1);
  transition: 0.5s;
}
.situation .process-item .float-img-box::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.situation .process-item:hover .float-img-box::before {
  opacity: 1;
  top: 0;
  background: var(
    --image-2-paints,
    linear-gradient(180deg, rgba(218, 37, 11, 0) 21.77%, #da250b 95.95%)
  );
  transition: 0.5s;
}
.situation .process-item:hover .float-img-box {
  filter: brightness(1) grayscale(0);
}

.situation .process-item .text-wrap {
  font-size: 38px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  position: relative;
  z-index: 3;
  margin-bottom: 80px;
}
.situation .process-item .text-wrap .count {
  color: #888;
  margin-bottom: 20px;
  font-weight: 500;
}
.situation .process-item .text-wrap .title {
  margin-bottom: 20px;
}
.situation .process-item .text-wrap .more-btn {
  display: none;
}
.situation .process-item:hover .text-wrap .count {
  color: #fff;
}
.situation .process-item:hover .text-wrap .more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
}
/* background: linear-gradient(90deg, rgba(17, 17, 17, 0.00) 20.07%, #111 67.46%), url(<path-to-image>) lightgray 50% / cover no-repeat; */
.situation .process-item.fix-pop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111;
  z-index: 3;
}
.situation .fix-pop-inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: relative;
}
.situation .fix-pop-inner .img-box {
  position: relative;
}
.situation .fix-pop-inner .img-box::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background: linear-gradient(
    270deg,
    rgb(17 17 17 / 100%) 2%,
    transparent 130%
  );
  transform: translateX(1px);
}
.situation .fix-pop-inner img {
  width: 100%;
  max-width: 800px;
}
.situation .fix-pop-inner .text-wrap {
  width: 100%;
  max-width: 710px;
  flex: 1;
  height: 100%;
  background: #111;
  margin: 0 0 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.situation .fix-pop-inner .heading {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #fff;
}
.situation .fix-pop-inner .heading .title {
  margin: 0;
}
.situation .fix-pop-inner .heading .count {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 13px;
  font-size: 26px;
  line-height: 31px;
  font-weight: 600;
  color: #fff;
  background-color: #da250b;
  margin: 0;
}
.situation .fix-pop-inner .emphasize-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  margin-bottom: 40px;
  background: #da250b;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.situation .fix-pop-inner .desc-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.situation .desc-item {
  display: flex;
  gap: 14px;
  font-size: 20px;
}
.situation .desc-item .title-box {
  width: 210px;
  text-align: left;
  font-weight: 700;
}
.situation .desc-item .sub-title-box {
  text-align: left;
  font-weight: 500;
  line-height: 27px;
  display: flex;
  flex-direction: column;
}
.situation .desc-item .sub-title-box p {
  font-size: 18px;
  font-weight: 500;
  color: #c4c4c4;
}
.situation .desc-item .sub-title {
  display: flex;
  gap: 5px;
}
.situation .desc-item .sub-title::before {
  content: "\002D";
}
.situation .desc-item .sub-title.in::before {
  content: "\2022";
}
.situation .desc-item .in.sub-title {
  padding-left: 35px;
  margin-bottom: 0;
  word-wrap: break-word;
  word-break: keep-all;
}

.situation .desc-item .sub-title.strong {
  color: #da250b;
}
.situation .desc-item .sub-title.strong::before {
  display: none;
}

.situation .fix-pop-inner .close-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  transform: translate(-50px, 40px);
  gap: 5px;
}
.situation .fix-pop-inner .close-btn span {
  white-space: nowrap;
}

.situation .dialog-inner {
  width: 100%;
  background: url("/src/resources/image/situation/dialog-bg.jpg") no-repeat
    center -1px / 1920px 868px #fff;
  padding-top: 80px;
}
.situation .dialog-inner .head-box {
  margin-bottom: 80px;
}

.situation .dialog-container {
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}
.situation .dialog-box {
  width: 628px;
  height: 628px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.hover-img {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.situation .dialog-list {
  position: relative;
  width: 490px;
  height: 490px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
}

.situation .dialog-item {
  position: absolute;
  font-size: 1.5em;
  width: 50%;
  height: 50%;
  transform-origin: 100% 0%;
  overflow: hidden;
  left: 50%;
  top: 50%;
  -webkit-transition: border 0.3s ease;
  -moz-transition: border 0.3s ease;
  transition: border 0.3s ease;
  cursor: pointer;
}

.situation .dialog-item:hover {
  background-color: #da250b;
}
.dialog-item.active {
  background: #da250b;
}
.dialog-item.active .dialog-text .title {
  color: #fff;
}

.situation .dialog-item:first-child {
  transform: translate(-100%, 0) rotate(45deg);
  border-top: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}
.situation .dialog-item:nth-child(2) {
  transform: translate(-100%, 0) rotate(135deg);
  border-top: 1px solid #e4e4e4;
}
.situation .dialog-item:nth-child(3) {
  transform: translate(-100%, 0) rotate(225deg);
  border-top: 1px solid #e4e4e4;
}
.situation .dialog-item:nth-child(4) {
  transform: translate(-100%, 0) rotate(315deg);
}

.dialog-item__inner {
  width: 100%;
  height: 100%;
  position: relative;
}
.dialog-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dialog-text .count {
  font-size: 46px;
  font-weight: 900;
  color: #888;
  opacity: 0.2;
}
.dialog-item.active .dialog-text .count {
  color: #fff;
}
.dialog-item:hover .dialog-text .count {
  color: #fff;
}
.dialog-item:hover .dialog-text .title {
  color: #fff;
}
.dialog-text .title {
  font-size: 30px;
  font-weight: 600;
  color: #c4c4c4;
}

/* .situation .dialog-box .dialog-logo {position:absolute; width: 198px; height: 198px;filter: drop-shadow(-5.993px 11.986px 23.971px rgba(205, 0, 0, 0.40)); background: linear-gradient(188deg, #da250b, #FF6D3F);border-radius: 50%; filter: drop-shadow(-10px 20px 40px rgba(205, 0, 0, 0.40)); display: flex; justify-content: center; align-items: center;} */
.situation .dialog-box .dialog-logo {
  position: absolute;
  width: 198px;
  height: 198px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #da250b;
  filter: drop-shadow(-10px 20px 40px rgba(205, 0, 0, 0.4));
  transform: translateZ(0); /* 아이폰 이슈 떄문에 추가, 하드웨어 가속 */
}
.situation .dialog-box .dialog-logo::after {
  content: "";
  width: 80%;
  height: 80%;
  background-color: #ff6d3f;
  position: absolute;
  border-radius: 50%;
  filter: blur(25px);
  transform: translateY(50%);
}
.situation .dialog-box .dialog-logo img {
  width: 90px;
  z-index: 1;
}
.situation .dialog-item:first-child .dialog-text {
  transform: translate(-40%, -60%) rotate(-45deg);
}
.situation .dialog-item:nth-child(2) .dialog-text {
  transform: translate(-40%, -60%) rotate(-135deg);
}
.situation .dialog-item:nth-child(3) .dialog-text {
  transform: translate(-40%, -60%) rotate(-225deg);
}
.situation .dialog-item:nth-child(4) .dialog-text {
  transform: translate(-40%, -60%) rotate(-315deg);
}

.situation .triangle-img {
  position: absolute;
  bottom: 0;
  width: 50px;
  transform: translateY(50%);
}

.situation .dialog-inner .text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 48px auto 0 auto;
}

.situation .dialog-inner .text-wrap .heading {
  width: 100%;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #c4c4c4;
}
.situation .dialog-inner .text-wrap .heading strong {
  color: #da250b;
  font-weight: 700;
}

.situation .dialog-inner .count-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}
.situation .dialog-inner .count-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.situation .dialog-inner .count-item .badge {
  font-size: 26px;
  font-weight: 600;
  background: #da250b;
  color: #fff;
  display: inline-block;
  text-align: center;
  padding: 5px 13px;
  margin-bottom: 10px;
}
.situation .dialog-inner .count-item .title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}
.situation .dialog-inner .count-item .sib-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #888;
  text-align: center;
}
.situation .dialog-inner .count-item .sib-title.emphasize {
  color: #da250b;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
/* E:: 4 상활별 대응 전략 */

/* --------------------------------------- */
/* --------------------------------------- */
/* COMPONENT ----------------------------- */
/* --------------------------------------- */

/* S:: COMPONENT - TAB*/
.history-navi-wrap {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  transform: translate(-50%, 50%);
  z-index: 4;
}
.history-navi-wrap .navi-box {
  background: rgba(218, 37, 11, 0.95);
}
.history-navi-wrap .history-box {
  position: absolute;
  top: -20px;
  transform: translateY(-100%);
}
.history-navi-wrap .history-box .history-list {
  display: flex;
  align-items: center;
  gap: 10px;
}
.history-navi-wrap .history-box .history-item {
  color: #fff;
}

.history-navi-wrap .navi-box .navi-list {
  display: flex;
  align-items: center;
}
.history-navi-wrap .navi-box .navi-item {
  flex: 1;
  padding: 30px 0;
  text-align: center;
}
.history-navi-wrap .navi-box .navi-item .link {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: #fff;
  position: relative;
}
.history-navi-wrap .navi-box .navi-item .active.link {
  font-weight: 800;
}
.history-navi-wrap .navi-box .navi-item .active.link::after {
  width: 7px;
  height: 7px;
  content: "";
  background: #fff;
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translate(-17px, -50%);
}
/* E:: COMPONENT - TAB*/

/* S:: COMPONENT - PAGINATION */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}
.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  color: #111;
}
.pagination-btn.active {
  color: #da250b;
}
.pagination-prev {
  padding: 0;
}
.pagination-prev img {
  width: 100%;
  transform: rotateZ(180deg);
}
.pagination .pagination-num-box {
  display: flex;
  gap: 25px;
  margin: 0 40px;
}
.pagination .pagination-num-box .pagination-btn {
  width: auto;
  line-height: 24px;
}
.pagination-arrow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
/* E:: COMPONENT - PAGINATION */

/* S:: COMPONENT - SEARCH_INPUT */
.search-input-form legend {
  display: none;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background: #f5f5f5;
}
.search-input {
  width: 100%;
  padding: 16px 30px 15px 30px;
  border: none;
  height: 100%;
}
.search-input::placeholder {
  font-weight: 500;
}
.search-svg {
  padding-right: 30px;
  cursor: pointer;
}
/* E:: COMPONENT - SEARCH_INPUT */

/* MB */
.history-navi-wrap .navi-box .tit {
  display: none;
}
